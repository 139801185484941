import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Code from "../assets/code.svg";
import Interactive from "../assets/interactive.svg";
import Layout from "../assets/layout.svg";
import Reactjs from "../assets/react.svg";
import Responsive from "../assets/responsive.svg";
import ResponsiveMobile from "../assets/responsive-mobile.svg";
import TypeScript from "../assets/typescript.svg";

gsap.registerPlugin(ScrollTrigger);

export default function Skills({isMobile, isiPad}) {
  const imgSkillsPairing = [
    ['HTML CSS JS', Code, 'Coding Bracket Inside Browser Icon'],
    ['React.js', Reactjs, 'React Logo Instide Browser Icon'],
    ['TypeScript', TypeScript, 'TypeScript Logo Instide Browser Icon'],
    ['UI Design', Layout, 'UI Layout Inside Browser Icon'],
    ['Interactive Design', Interactive, 'Interactive Design Inside Browser Icon'],
    ['Responsive Design', isMobile || isiPad ? ResponsiveMobile : Responsive, 'Responsive Design Inside Browser Icon'],
  ];
  const bgRef = useRef(null);
  const containerRef = useRef(null);
  const textsRef = useRef(null);
  const skillsRef = useRef(null);
  useGSAP(() => {
    // Pin Background
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom bottom',
      pin: containerRef.current,
      pinSpacing: false,
    })
    // Skills Intro Text Showing Animation
    gsap.fromTo(textsRef.current.children, {opacity: 0}, {
      opacity: 1,
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'center center',
        end: 'center top',
        scrub: true,
      },
      stagger: 0.5,
    })
    // Showing/Hiding Skill Images Animation
    gsap.fromTo(skillsRef.current, {opacity: 0}, {
      opacity: 1,
      scrollTrigger: {
        trigger: bgRef.current,
        start: '60% center',
        toggleActions: 'play noce noce reverse',
      },
      duration: 1,
    })
    // Skill Images Floating Across Animation for Tablet and Desktop
    gsap.matchMedia().add("(min-width: 768px)", () => {
      gsap.fromTo(skillsRef.current, {x: 0}, {
        xPercent: -100,
        duration: 28,
        ease: 'none',
        repeat: -1,
      })
    });
  })
  return (
    <div ref={bgRef} className={`h-[225vh] bg-dark overflow-hidden`}>
      <div ref={containerRef} className={`h-screen flex flex-col justify-evenly items-center`}>
        {/** Skills Intro Text */}
        <h1 ref={textsRef} className={`text-4xl md:text-6xl lg:text-7xl text-red text-center font-thin z-10`}><span>OH</span><span className={`font-semibold`}> SKILLS</span><span className={`leading-loose lg:leading-normal`}> ?</span><br className={`lg:hidden`}/><span>&nbsp; YEAH,</span><span> I</span><span> GOT</span><span> MANY...</span></h1>
        {/** Skill Images */}
        <div ref={skillsRef} className={`md:flex`}>
          <div className={`w-screen md:w-[150vw] lg:w-screen grid grid-cols-2 gap-y-6 md:flex justify-around text-white`}>
            {
              imgSkillsPairing.map((skill, index) => {
                return <div key={index} className={`flex flex-col items-center md:space-y-12`}>
                  <img src={skill[1]} alt={skill[2]}/>
                  <p>{skill[0]}</p>
                </div>
              })
            }
          </div>
          {/** 2nd Floating Skills List for Tablet and Desktop */}
          {
            !isMobile && (
              <div className={`md:w-[150vw] lg:w-screen md:flex justify-around text-white absolute left-full`}>
                {
                  imgSkillsPairing.map((skill, index) => {
                    return <div key={index} className={`flex flex-col items-center space-y-12`}>
                      <img src={skill[1]} alt={skill[2]}/>
                      <p>{skill[0]}</p>
                    </div>
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
};