import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Landing({isMobile, isiPad}) {
  const bgRef = useRef(null);
  const lNameRef = useRef(null);
  const rNameRef = useRef(null);
  const lDescriptionRef = useRef(null);
  const rDescriptionRef = useRef(null);
  const arrowRef = useRef(null);
  useGSAP(() => {
    // Pin Background
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
    })
    const tl = gsap.timeline();
    // Name Animation
    tl.from([lNameRef.current, rNameRef.current], {x: (index) => index === 0 ? -window.innerWidth : window.innerWidth, duration: 2, ease: 'power4.out'})
      // Description Animation with Mobile Condition
      .from([lDescriptionRef.current, rDescriptionRef.current], {yPercent: 50, opacity: 0, duration: 2, ease: 'power4.out', stagger: isMobile || isiPad ? 1 : 0}, 1)
      // Arrow Animation
      .fromTo(arrowRef.current, {yPercent: -100, opacity: 0}, {yPercent: 150, opacity: 1, repeat: -1, duration: 3, ease: 'power3.inOut'}, 0);
  });
  return (
    <div ref={bgRef} className={`h-dvh lg:h-[100vh] flex flex-col justify-center space-y-12 px-6 md:px-12 lg:px-16 -z-10`}>
        {/** Names */}
        <div className={`antonio md:flex md:justify-between text-red text-center text-[36vw] md:text-[17.5vw] lg:text-[19vw] font-semibold lg:font-bold leading-none`}>
          <h1 ref={lNameRef}>JASON</h1>
          <h1 ref={rNameRef}>ZHANG</h1>
        </div>
        {/** Descriptions */}
        <div className={`lg:flex lg:flex-row lg:justify-between text-center space-y-6 lg:space-y-0 tracking-wider font-light`}>
          <h2 ref={lDescriptionRef} className={`lg:text-start`}>A <span className={`font-bold`}>CREATIVE FRONTEND DEVELOPER</span> WHO LOVES<br className={`hidden md:inline`}/> TRANSFORMING IMAGINATIVE IDEAS INTO DIGITAL REALITIES</h2>
          <h2 ref={rDescriptionRef} className={`lg:text-end`}>CURRENTLY<br className={`hidden lg:inline`}/> BASED IN NORTH CAROLINA</h2>
        </div>
        {/** Scroll Indicator */}
        <h1 ref={arrowRef} className={`absolute bottom-6 left-1/2 -translate-x-1/2 text-4xl font-bold text-red`}>↓</h1>
    </div>
  )
};