import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LandingImg from "../assets/landing.jpg";

gsap.registerPlugin(ScrollTrigger);

export default function About({isMobile, isiPad}) {
  const bgRef = useRef(null);
  const containerRef = useRef(null);
  const aboutRef = useRef(null);
  const meRef = useRef(null);
  const imgRef = useRef(null);
  const passionRef = useRef(null);
  const journeyRef = useRef(null);
  useGSAP(() => {
    // Pin Background
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom bottom',
      pin: containerRef.current,
      pinSpacing: false,
    })
    // Intro Text Animation
    gsap.from([aboutRef.current, meRef.current], {
      x: (index) => (index === 0 ? -window.innerWidth : window.innerWidth),
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top center',
      },
      duration: 2,
      ease: 'power4.out',
    })
    // Image Entering Animation
    gsap.from(imgRef.current, {
      y: window.innerHeight,
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top top',
      },
      duration: 2,
      ease: 'power4.out',
    })
    // Intro Text Disappearing Animation
    gsap.to([aboutRef.current, meRef.current], {
      opacity: 0,
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top top',
        end: '25% top',
        scrub: 2,
      }
    })

    // Image Rotation Animation
    gsap.timeline({
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top top',
        end: '120% top',
        scrub: 2,
        toggleAttribute: "play reverse play reverse",
      }
    })
    .to(imgRef.current, {rotation: 0, opacity: isMobile || isiPad ? 0.15 : 1})
    .to(imgRef.current, {rotation: 12, opacity: 0})

    // Journey Animation
    gsap.timeline({
      scrollTrigger: {
        trigger: bgRef.current,
        start: '25% top',
        end: '50% top',
        scrub: true,
        toggleAttribute: "play reverse play reverse",
      }
    })
    .fromTo(journeyRef.current, {opacity: 0, yPercent: 20}, {opacity: 1, duration: 0.5, yPercent: 0, ease: 'power4.out'})
    .to(journeyRef.current, {opacity: 0, duration: 0.5, yPercent: -20}, 0.5)

    // Passion Animation
    gsap.timeline({
      scrollTrigger: {
        trigger: bgRef.current,
        start: '50% top',
        end: '75% top',
        scrub: true,
        toggleAttribute: "play reverse play reverse",
      }
    })
    .fromTo(passionRef.current, {opacity: 0, yPercent: 20}, {opacity: 1, duration: 0.5, yPercent: 0, ease: 'power4.out'})
    .to(passionRef.current, {opacity: 0, duration: 0.5, yPercent: -20}, 0.5)
  })
  return (
    <div id={`about`} ref={bgRef} className={`h-[400vh] bg-dark px-6 md:px-12 lg:px-16 overflow-hidden`}>
      <div ref={containerRef} className={`h-screen flex justify-center items-center text-white`}>
        {/** Image */}
        <img ref={imgRef} src={LandingImg} alt="Portrait of Jason Zhang" className={`md:w-[42%] lg:w-1/4 opacity-50 rotate-12`}/>
        {/** Background Text */}
        <div className={`antonio absolute flex flex-col md:flex-row text-center text-[30vw] md:text-[20vw] lg:text-[15vw] font-medium lg:font-semibold leading-none md:space-x-12`}>
          <h1 ref={aboutRef}>ABOUT</h1>
          <h1 ref={meRef}>ME</h1>
        </div>
        {/** Journey Section */}
        <div className={`absolute`}>
          <div ref={journeyRef} className={`flex flex-col lg:flex-row lg:items-center justify-between leading-relaxed space-y-8 lg:space-y-0`}>
            <h1 className={`text-4xl lg:text-8xl text-red font-thin`}>JOURNEY</h1>
            <p className={`w-auto md:w-[60%] lg:w-1/4`}>I graduated from Georgia Tech with a Computer Science degree in May 2023. After graduation, I made a career shift and ventured into restaurant management with the goal of eventually starting my own business. However, over time, I began to miss the creativity and technical challenges of being a developer. I decided to return to my passion and started freelancing. Since then, I have completed a few freelance projects for small businesses and individuals.</p>
          </div>
        </div>
        {/** Passion Section */}
        <div className={`absolute`}>
          <div ref={passionRef} className={`flex flex-col lg:flex-row lg:items-center justify-between leading-relaxed space-y-8 lg:space-y-0`}>
            <h1 className={`text-4xl lg:text-8xl text-red font-thin`}>PASSION</h1>
            <p className={`w-auto md:w-[60%] lg:w-1/4`}>As a developer, my passion lies in crafting and implementing visually stunning and highly functional user interfaces. My journey began during my first Web Dev course, where I discovered the joy of transforming design concepts into digital forms. The ability to create purposeful interfaces that address real-world challenges ignites my drive and ambition, continuously propelling me towards greater achievements.</p>
          </div>
        </div>
      </div>
    </div>
  )
};