import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Logo from "../assets/logo.svg";
import "../hamburgers.css";

gsap.registerPlugin(ScrollToPlugin);

export default function Navbar() {
  const [panelShow, setPanelShow] = useState(false);
  const panelRef = useRef(null);
  const menuItems = [
    {id: 1, label: 'ABOUT', onClick: () => handleClick('about', false), onTouch: () => handleClick('about', true)},
    {id: 2, label: 'WORKS', onClick: () => handleClick('works', false), onTouch: () => handleClick('works', true)},
    {id: 3, label: 'CONTACT', onClick: () => handleClick('contact', false), onTouch: () => handleClick('contact', true)}
  ];
  useEffect(() => {
    // Disable/Able Scroll Based on PanelShow
    if (panelShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  })
  useGSAP(() => {
    // Panel Animation
    gsap.to(panelRef.current, {
      xPercent: panelShow ? -100 : 100,
      duration: 2,
      ease: 'power4.out'
    });
  }, [panelShow])
  const handleClick = (id, isTouchScreen) => {
    if (isTouchScreen) {
      setPanelShow(prev => !prev);
    }
    gsap.to(window, {
      scrollTo: {
        y: `#${id}`,
      },
      duration: 5,
      ease: 'power4.out',
    })
  }
  return (
    <>
      <nav className={`w-screen h-[8vh] fixed flex items-center justify-between lg:justify-center px-6 md:px-12 mt-2 z-30`}>
        {/** Horinzontal Navbar - Desktop */}
        <div className={`hidden lg:flex space-x-12 text-red`}>
          {menuItems.map((tab, id) => (
            <button key={id} data-after={tab.label} onClick={tab.onClick} className={`nav-link`}><span>{tab.label}</span></button>
          ))}
        </div>
        {/** Navbar - Mobile & Tablets */}
        <button className={`w-[36px] lg:hidden`}><img draggable={false} src={Logo} alt="JZ logo"/></button>
        <button onClick={() => setPanelShow(prev => !prev)} className={`hamburger hamburger--spin ${panelShow && `is-active`} lg:hidden`}>
          <span className={`hamburger-box`}>
            <span className={`hamburger-inner`}></span>
          </span>
        </button>
      </nav>

      {/** Navbar Side Panel - Mobile & Tablets */}
      <div ref={panelRef} className={`w-screen h-screen bg-red fixed right-[-100%] flex flex-col items-end justify-center text-white pr-6 md:pr-12 space-y-24 md:space-y-36 z-20 lg:hidden`}>
        {menuItems.map((tab, id) =>
          <button key={id} onClick={tab.onTouch} className={`text-6xl`}>{tab.label}</button>
        )}
      </div>
    </>
  )
};