import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dealFlower from "../assets/dealFlower.png";
import goldenChina from "../assets/goldenChina.png";

gsap.registerPlugin(ScrollTrigger);

export default function Projects({isMobile, isiPad}) {
  const bgRef = useRef(null);
  const containerRef = useRef(null);
  const titleRef = useRef(null)
  const imgRefs = useRef([]);
  const textRefs = useRef([]);
  imgRefs.current = [];
  textRefs.current = [];
  const addImgRef = (el, index) => {
    imgRefs.current[index] = el;
  };
  const addTextRef = (el, index) => {
    textRefs.current[index] = el;
  };
  const projects = [
    {name: 'GC RESTAURANT', role: 'Local Business | Designer + Developer', description: 'Worked with a local small business to create an online website that allows customers to easily navigate the menu, discover new specials, and access basic store information', tech: 'Tech Stack: Figma, React, MaterialUI', picture: goldenChina, link: 'https://goldenchina.xyz'},
    {name: 'DEAL FLOWER', role: 'Startup Concept | Designer + Developer', description: 'Collaborated with a small group of software engineers to attempt a startup concept: develop an online platform that allows users to monitor the social media activities of venture capitalists they choose to track', additionalInfo: 'the link below takes you to the “Interest Signup” site', tech: 'Tech Stack: Figma, React, TailwindCSS', picture: dealFlower, link: 'https://jasonyjzhang.github.io/deal_flower_launch'},
  ]
  const handleClick = (projectLink) => {
    window.open(projectLink, '_blank');
  }
  useGSAP(() => {
    // Mapping
    const textImgMapping = imgRefs.current.map((img, index) => [textRefs.current[index], img]);
    // Pin Background
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom bottom',
      pin: titleRef.current,
      pinSpacing: false,
    })
    // Title ("Project") Animation
    gsap.from(titleRef.current.children, {
      x: (index) => (index === 0 ? -window.innerWidth : window.innerWidth),
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top center',
      },
      duration: 2,
      ease: 'power4.out',
    })
    // Title Darking Animation
    gsap.to(titleRef.current.children, {
      opacity: 0.07,
      scrollTrigger: {
        trigger: bgRef.current,
        start: 'top top',
        end: 'top -50%',
        scrub: 2,
      }
    })
    // Project Text Stopping Animation
    if (!isMobile) {
      textImgMapping.map((pair) => {
        return ScrollTrigger.create({
          trigger: pair[1],
          start: 'top 25%',
          end: `+=${pair[1].offsetWidth - pair[0].offsetHeight}`,
          pin: pair[0],
          pinSpacing: false,
        })
      })
    }
  })
  return (
    <div id={`works`} ref={bgRef} className={`h-[320vh] bg-dark px-6 md:px-10 lg:px-16 overflow-hidden`}>
      {/** Pinned Container */}
      <div ref={containerRef} className={`h-screen flex justify-center items-center`}>
        {/** Project Intro Text */}
        <div ref={titleRef} className={`antonio flex flex-row items-center justify-center text-8xl md:text-[20vw] lg:text-[15vw] text-white font-medium lg:font-semibold leading-none`}>
          <h1>PRO</h1>
          <h1>JECTS</h1>
        </div>
      </div>
      {/** Project Detail Section */}
      <div className={`w-full space-y-36 lg:space-y-48 pb-36 lg:pb-48`}>
        {
          projects.map((project, index) => {
            return (
              <div className={`flex flex-col md:flex-row justify-between`}>
                {/** Porject Image */}
                <img ref={el => addImgRef(el, index)} src={project.picture} alt={`${project.name} Project Cover`} className={`w-full md:w-[48%] lg:w-[45%] z-10`}/>
                {/** Porject Description */}
                <div ref={el => addTextRef(el, index)} className={`w-full md:w-[48%] lg:w-[45%] h-fit text-white font-extralight pt-6 md:pt-0 space-y-6 lg:space-y-12 z-10`}>
                  <p>{project.role}</p>
                  <h1 className={`text-4xl lg:text-6xl text-red font-normal`}>{project.name}</h1>
                  <p>{project.tech}</p>
                  <p>{project.description}</p>
                  {
                    project.additionalInfo && (
                      <p className={`italic text-gray-400`}>{project.additionalInfo}</p>
                    )
                  }
                  <button onClick={() => handleClick(project.link)} className={`w-full md:w-2/3 lg:w-fit border-2 border-red text-red px-8 py-2`}>Visit Site</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}