import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Outro({isMobile, isiPad}) {
  const bgRef = useRef(null);
  const topTextRef = useRef(null);
  const bottomTextRef = useRef(null);
  const iPadMultiplier = isiPad ? 1.5 : 1;
  useGSAP(() => {
    // Word Animation for Tablet and Desktop
    if(!isMobile) {
      gsap.to([topTextRef.current, bottomTextRef.current], {
        yPercent: (index) => index === 0 ? -200 * iPadMultiplier : 200 * iPadMultiplier,
        scrollTrigger: {
          trigger: bgRef.current,
          start: 'center 75%',
          end: 'center 25%',
          toggleActions: 'play reverse play reverse',
          onEnter: () => gsap.to(bgRef.current, {backgroundColor: '#FF4D4D', ease: 'power4.out'}),
          onLeaveBack: () => gsap.to(bgRef.current, {backgroundColor: '#0F0F0F', ease: 'power4.out', delay: 0.5})},
        duration: 0.5,
        ease: 'power4.out'
      })
    }
  })
  return (
    <div ref={bgRef} className={`h-screen bg-red md:bg-dark flex flex-col justify-evenly items-center text-transparent text-7xl md:text-[6vw] overflow-hidden`}>
      <h1 ref={topTextRef} className={`hidden md:inline-block absolute outline-text`}>GET IN TOUCH</h1>
      <h1 className={`absolute text-white text-center leading-relaxed`}>GET IN TOUCH</h1>
      <h1 ref={bottomTextRef} className={`hidden md:inline-block absolute outline-text`}>GET IN TOUCH</h1>
    </div>
  )
};