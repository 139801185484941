import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Contacts() {
  const handleEmailClick = () => {
    window.location.href = "mailto:hello@jasonzhang.studio";
  };
  const bgRef = useRef(null);
  useGSAP(() => {
    ScrollTrigger.create({
      trigger: bgRef.current,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
    })
  })
  return (
    // (adding absolute to bgRef element fixed the problem of not being able to make the button clickable due to overlay)
    <div id={`contact`} ref={bgRef} className={`w-screen h-screen -translate-y-full absolute flex flex-col justify-center items-center space-y-12 px-6 md:px-12 lg:px-16 overflow-hidden -z-10`}>
      {/** Contact Link */}
      <button onClick={handleEmailClick} className={`antonio text-red text-[18vw] font-semibold underline md:hidden`}>CONTACT ME</button>
      <button onClick={handleEmailClick} className={`antonio hidden md:inline-block text-red md:text-[7vw] lg:text-[8.5vw] font-semibold lg:font-bold tracking-wide underline`}>HELLO@JASONZHANG.STUDIO</button>
      {/** Closing Text */}
      <div className={`w-full lg:flex lg:flex-row lg:justify-between text-center space-y-6 lg:space-y-0 tracking-wide font-light`}>
        <p className={`hidden lg:inline-block lg:text-start`}>ALL GOOD THINGS <br/> COME TO AN END, BUT LET'S NOT JUST END HERE!</p>
        <p className={`lg:text-end`}>SHOOT ME A MESSAGE <br/> IF YOU HAVE ANY FEEDBACK, IDEAS, OR JUST WANNA SAY HI!</p>
      </div>
      <p className={`absolute bottom-6`}>DESIGNED & BUILT WITH &lt;3</p>
    </div>
  )
};